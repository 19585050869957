import axios from 'axios';
import mocksData from './../mocks/mocks_values.json';
const httpClient = axios.create();

// httpClient.defaults.timeout = 2000;
httpClient.interceptors.response.use(
  (response) => response,
  (response) => {
    console.log('Error', response);
    // return handleError({ message, data, status });
  }
);

export const getDropdownValuesAPI = async () => {
  try {
    // const response = await httpClient.get(
    //   "https://jsonplaceholder.typicode.comf/todos/1"
    // );
    // if (response?.status === 200) {
    //   return response?.data;
    // } else {
    return mocksData;
    // }
  } catch (error) {
    return mocksData;
  }
};

export const postFormData = async (
  authenticated,
  payload,
  successCallback = () => true,
  errorCallback = () => true
) => {
  let formId = authenticated
    ? process.env.REACT_APP_FORM_ID_AUTHENTICATED
    : process.env.REACT_APP_FORM_ID_NOT_AUTHENTICATED;

  if (process.env.REACT_APP_ENV === 'stage') {
    formId = process.env.REACT_APP_FORM_ID_AUTHENTICATED;
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  console.log(
    '[ API PARAMS ]',
    JSON.stringify(payload),
    formId,
    process.env.REACT_APP_FORM_PORTAL_ID,
    `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_FORM_PORTAL_ID}/${formId}`
  );

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_FORM_PORTAL_ID}/${formId}`;
  const response = await httpClient.post(url, JSON.stringify(payload), {
    headers,
  });

  if (response?.status === 200 || response?.status === 201) {
    // success
    successCallback(response?.data);
  } else {
    // error
    errorCallback();
  }
};

export const postImage = async (
  image,
  successCallback = () => true,
  errorCallback = () => true
) => {
  const url = `https://api.hubapi.com/filemanager/api/v3/files/upload`;

  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  const response = await httpClient.post(url, image, { headers });
  if (response?.status === 200 || response?.status === 201) {
    successCallback(response?.data);
  } else {
    errorCallback();
  }
};
