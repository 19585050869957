import React from "react";

const ThankYouPage = () => {
  return (
    <div
      className="App tag-pb-xlarge tag-pt-regular"
      style={{ background_color: "red" }}
    >
      <div className="container">
        <h2 className="tag-h2 tag-left_text">Thank you</h2>
      </div>
    </div>
  );
};

export default ThankYouPage;
