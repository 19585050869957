import { useEffect } from "react";

const ErrorToast = ({ closeToast = () => true, showToast = false }) => {
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        closeToast();
      }, 5000);
    }
  }, [showToast, closeToast]);

  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "10px 14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className={"tag-bg-error_opacity20 tag-mb-large"}
    >
      <div>
        <p className={"tag-label_bold"}>Ups, something went wrong.</p>
        <p className={"tag-label_regular"}>Please retry.</p>
      </div>
      <div onClick={closeToast} style={{ cursor: "pointer" }}>
        <i
          className={"icon-close"}
          style={{ fontSize: "20px", color: "black" }}
        />
      </div>
    </div>
  );
};

export default ErrorToast;
