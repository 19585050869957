const TextBox = ({
  formName,
  labelText,
  isRequired = false,
  value,
  placeholder,
  type,
  onChangeText = () => true,
  id,
  customClasses = "",
}) => {
  return (
    <div
      className={`tag-input__container ${customClasses ? customClasses : null}`}
    >
      <label
        htmlFor={formName}
        className={`input__label ${isRequired ? "input__label--required" : ""}`}
        data-required={isRequired.toString()}
      >
        {labelText}
      </label>
      <textarea
        type={type}
        id={id}
        name={formName}
        placeholder={placeholder}
        className="input__form"
        value={value}
        onChange={(e) => onChangeText(e?.target?.value)}
        required={isRequired}
      >
        {value}
      </textarea>
    </div>
  );
};

export default TextBox;
