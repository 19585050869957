import { useState } from 'react';
const InputForm = ({
  formName,
  labelText,
  isRequired = false,
  value,
  placeholder,
  type,
  onChangeText = () => true,
  id,
  customClasses = '',
  disabled = false,
  error = '',
}) => {
  const [touched, setTouched] = useState(false);
  return (
    <div className={`tag-input__container ${customClasses ? customClasses : null}`}>
      <label
        htmlFor={formName}
        className={`input__label ${isRequired ? 'input__label--required' : ''}`}
        data-required={isRequired.toString()}
      >
        {labelText}
      </label>
      <input
        type={type}
        id={id}
        name={formName}
        placeholder={placeholder}
        className={`input__form ${!!error && touched ? 'input__form--error' : null}`}
        value={value}
        onChange={(e) => onChangeText(e?.target?.value)}
        required={isRequired}
        disabled={disabled}
        onBlur={() => setTouched(true)}
      />
      {!!error && touched ? (
        <p className={'tag-label_small tag-c-error tag-mt-tiny'}>{error}</p>
      ) : (
        <p className={'tag-label_small tag-mt-tiny'}>&nbsp;</p>
      )}
    </div>
  );
};

export default InputForm;
