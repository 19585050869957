import React, { useEffect, useState } from 'react';

const SelectForm = ({
  labelText,
  formName,
  isRequired = false,
  data = [],
  onChange = () => true,
  customClasses = '',
  selectedDefaultText = '',
  selectedDefaultEmpty = true,
  defaultValue = '',
  forceCloseDropdown = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const value = !!defaultValue
      ? defaultValue
      : selectedDefaultEmpty
      ? ''
      : data[0]?.value || data[0];
    setSelectedValue(value);
  }, [data, defaultValue, selectedDefaultEmpty, selectedDefaultText]);
  useEffect(() => {
    if (!forceCloseDropdown) {
      return;
    }
    showDropdown && setShowDropdown(!showDropdown);
  }, [forceCloseDropdown, setShowDropdown, showDropdown]);

  return (
    <div className={`tag-input__container ${customClasses}`}>
      <label
        htmlFor={formName}
        className={`input__label ${isRequired ? 'input__label--required' : ''}`}
      >
        {labelText}
      </label>
      <select
        name={formName}
        id={formName}
        required={isRequired}
        onChange={(ev) => {
          setSelectedValue(ev.target?.value);
          onChange(ev.target?.value);
        }}
        className={`select__form`}
        value={selectedValue}
      >
        <option value={''} disabled>
          {selectedDefaultText || ' -- select an option -- '}
        </option>
        {data?.map((elem) => {
          return (
            <option
              key={elem?.label || elem}
              className='option__select'
              value={elem?.value || elem}
            >
              {elem?.label || elem}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectForm;
