const InputFile = ({
  formName,
  labelText,
  type,
  id,
  size,
  onChange = () => type,
  subText = "",
}) => {
  return (
    <div className={"tag-input__container"}>
      <label htmlFor={formName} className={"input__label"}>
        {labelText}
      </label>
      {subText && <div className="input__subtext">{subText}</div>}
      <input
        className="tag-input__file"
        type={type}
        id={id}
        name={formName}
        size={size}
        multiple={false}
        onChange={onChange}
      />
    </div>
  );
};

export default InputFile;
