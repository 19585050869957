import './Footer.scss';

const Footer = () => {
  return (
    <div className={'footer'}>
      <div className={'details'}>
        <a
          itemProp="url"
          href="https://talentgarden.org/en"
          target="_blank"
          rel="noreferrer"
          className={'logo-link'}
        >
          <img src="images/logo_white.png" style={{ height: '90px' }} alt="" />
        </a>
        <div className={'tag-info'}>
          <p className={'tag-label_bold'}>TALENT GARDEN S.P.A.</p>
          <p className={'tag-label_regular'}>
            {'Piazza Città di Lombardia n. 1 20124\nMilan, Italy '}
          </p>
        </div>
        <div className="social-share">
          <ul className="">
            <li>
              <a href="https://www.facebook.com/talentgarden" target="_blank" rel="noreferrer">
                <img
                  src="/images/facebook.svg"
                  height="15"
                  alt="talent garden facebook"
                  className="social-icon"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/talentgarden/" target="_blank" rel="noreferrer">
              <img
                  src="/images/instagram.svg"
                  height="15"
                  alt="talent garden instagram"
                  className="social-icon"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/talent-garden"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/linkedin.svg"
                  height="15"
                  alt="talent garden linkedin"
                  className="social-icon"
                />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/TalentGardenen" target="_blank" rel="noreferrer">
              <img
                  src="/images/twitter.svg"
                  height="15"
                  alt="talent garden twitter"
                  className="social-icon"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/TalentGardenOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/youtube.svg"
                  height="15"
                  alt="talent garden youtube"
                  className="social-icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={'disclaimer'}>
        <span>
          {`©${new Date().getFullYear()} Talent Garden S.p.A. - Nominal Capital €492.354 - REA MI-2099746
          - P.IVA/VAT IT03340710981 | `}
        </span>
        <a href="https://talentgarden.org/en/privacy-policy/" className={'link '}>
          Privacy Policy&nbsp;
        </a>
        -
        <a href="https://talentgarden.org/en/cookie-policy/" className={'link '}>
          &nbsp;Cookie Policy
        </a>
      </div>
    </div>
  );
};

export default Footer;
