import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import ThankYouPage from "./pages/ThankYouPage";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/thankyou">
          <ThankYouPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
